import React, { useState, useEffect } from "react";
import { Box, Card, Typography, Grid } from "@mui/material";
import RainfallAreaRangeGraph from "./RainfallAreaRangeGraph";
import { MenuItem, Select, FormControl, InputLabel } from "@mui/material";
import VegetationAreaRangeGraph from "./VegetationAreaRangeGraph";
import SoilMoistureAreaRangeGraph from "./SoilMoistureAreaRangeGraph";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify"; // Import ToastContainer and toast
import "react-toastify/dist/ReactToastify.css"; // Import toast styles
import { Auth } from "aws-amplify";

const ClimateRiskTab = () => {
  const [selectedValue, setSelectedValue] = useState("Rainfall");
  const [tamsatDailyData, setTamsatDailyData] = useState([]);
  const [soilMoistureData, setSoilMoistureData] = useState([]);
  const [ModisNDVIData, setmodisNDVIData] = useState([]);
  const [sentinel2NDVIData, setsentinel2NDVIData] = useState([]);
  const PolicystartDate = useSelector((state) => state.PolicystartDate);
  const PolicyendDate = useSelector((state) => state.PolicyendDate);
  const LoanID = useSelector((state) => state.LoanID);
  const [summaryProbability, setSummaryProbability] = useState([]);
  const [averagePercentage, setAveragePercentage] = useState(0);
  const [historicalCropRiskScore, sethistoricalCropRiskScore] = useState(0);
  const [historicalsummaryProbability, setHistoricalSummaryProbability] = useState([]);
  const enterprise = useSelector((state)=>state.enterprise)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const { accessToken: { jwtToken } } = await Auth.currentSession();
        let response;
        if (selectedValue === "Rainfall") {
          response = await fetch(
            `/api/monitoring/MinMaxDailyGraphValues?country=Kenya&level1=Baringo&level2=BaringoCentral&level3=Kabarnet&end_date=${PolicyendDate}&dataset=tamsatDaily&start_date=${PolicystartDate}`
            ,{
              method: "GET", // Define the HTTP method (optional, GET is default)
              headers: {
                "Content-Type": "application/json",
                Authorization:jwtToken, // Set the Authorization header
              },
            });
          const data = await response.json();
          setTamsatDailyData(data);
        } else if (selectedValue === "Soil Moisture") {
          response = await fetch(
            `/api/monitoring/compareMinMaxVegetationValues?end_date=${PolicyendDate}&dataset=volumetricSSM&start_date=${PolicystartDate}&country=Kenya&level1=Baringo&level2=805&level3=Lembus`,{
              method: "GET", // Define the HTTP method (optional, GET is default)
              headers: {
                "Content-Type": "application/json",
                Authorization:jwtToken, // Set the Authorization header
              },
            }
          );
          const data = await response.json();
          setSoilMoistureData(data);
        } else if (selectedValue === "Modis") {
          response = await fetch(
            `/api/monitoring/compareMinMaxVegetationValues?end_date=${PolicyendDate}&dataset=modisNDVI&start_date=${PolicystartDate}&country=Kenya&level1=Baringo&level2=BaringoCentral&level3=Kabarnet`,{
              method: "GET", // Define the HTTP method (optional, GET is default)
              headers: {
                "Content-Type": "application/json",
                Authorization:jwtToken, // Set the Authorization header
              },
            }
          );
          const data = await response.json();
          setmodisNDVIData(data);
        } else if (selectedValue === "sentinel") {
          response = await fetch(
            `/api/monitoring/compareMinMaxVegetationValues?end_date=${PolicyendDate}&dataset=sentinel2NDVI&start_date=${PolicystartDate}&country=Kenya&level1=Baringo&level2=BaringoCentral&level3=Kabarnet`,{
              method: "GET", // Define the HTTP method (optional, GET is default)
              headers: {
                "Content-Type": "application/json",
                Authorization:jwtToken, // Set the Authorization header
              },
            }
          );
          const data = await response.json();
          setsentinel2NDVIData(data);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedValue]); // This effect only runs when `selectedValue` changes

  useEffect(() => {
    const fetchCropRiskScore = async () => {
      try {
        const { accessToken: { jwtToken } } = await Auth.currentSession();
        const response = await fetch(
          `/api/monitoring/currentCropRiskScore?loan_id=${LoanID}&enterprise=${enterprise}`,{
            method: "GET", // Define the HTTP method (optional, GET is default)
            headers: {
              "Content-Type": "application/json",
              Authorization:jwtToken, // Set the Authorization header
            },
           
          }
        );

        if (response.status === 404) {
          setAveragePercentage("NA");
          toast.error("No crop found for this loan_id", {
            style: { fontSize: "14px" }, // Reduced font size of the toast
          });
          return;
        }

        const data = await response.json();
        setSummaryProbability(data.summary_percentage_probability);

        // Calculate averagePercentage
        const avgPercentage = Math.round(
          Object.values(data.summary_percentage_probability).reduce(
            (acc, val) => acc + val.Percentage,
            0
          ) / Object.values(data.summary_percentage_probability).length
        );
        setAveragePercentage(avgPercentage);
      } catch (error) {
        console.error("Error fetching crop risk score data:", error);
        setAveragePercentage("NA");
      }
    };

    fetchCropRiskScore();
  }, [LoanID]);

  useEffect(() => {
    const fetchHistoricalCropRiskScore = async () => {
      try {
        const { accessToken: { jwtToken } } = await Auth.currentSession();
        const response = await fetch(
          `/api/monitoring/historicalCropRiskScore?loan_id=${LoanID}&enterprise=${enterprise}`,{
            method: "GET", // Define the HTTP method (optional, GET is default)
            headers: {
              "Content-Type": "application/json",
              Authorization:jwtToken, // Set the Authorization header
            },
          }
        );

        if (response.status === 404) {
          sethistoricalCropRiskScore("NA");
          toast.error("No crop found for this loan_id", {
            style: { fontSize: "14px" }, // Reduced font size of the toast
          });
          return;
        }

        const data = await response.json();
        setHistoricalSummaryProbability(data.summary_percentage_probability);

        // Calculate averagePercentage
        const avgPercentage = Math.round(
          Object.values(data.summary_percentage_probability).reduce(
            (acc, val) => acc + val.Percentage,
            0
          ) / Object.values(data.summary_percentage_probability).length
        );
        sethistoricalCropRiskScore(avgPercentage);
      } catch (error) {
        console.error("Error fetching crop risk score data:", error);
        sethistoricalCropRiskScore("NA");
      }
    };

    fetchHistoricalCropRiskScore();
  }, [LoanID]);

  const handleSelectChange = (event) => {
    setSelectedValue(event.target.value);
  };
  return (
    <div>
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        gap: "10px",
        justifyContent: "space-between",
        marginBottom: "30px",
      }}
    >
      <Grid container spacing={1}>
        <Grid item xs={12} md={4.5}>
          <Card
            sx={{
              width: "100%",
              height: "140px",
              borderRadius: "8px",
              padding: "16px 24px",
              backgroundColor: "#FBFDFF",
              border: "0.4px solid #000",
              marginRight:'10%'
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "20px",
                color: "#706F6C",
              }}
            >
              Score During Loan Sanction
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "32px",
                color:
                  historicalCropRiskScore === "NA"
                    ? "#000"
                    : historicalCropRiskScore >= 0 &&
                      historicalCropRiskScore <= 40
                    ? "red"
                    : historicalCropRiskScore >= 41 &&
                      historicalCropRiskScore <= 60
                    ? "#FFB727"
                    : historicalCropRiskScore >= 61 &&
                      historicalCropRiskScore <= 90
                    ? "#01B827"
                    : historicalCropRiskScore >= 91 &&
                      historicalCropRiskScore <= 100
                    ? "#026B13"
                    : "#000",
              }}
            >
              {historicalCropRiskScore === "NA"
                ? "NA"
                : `${historicalCropRiskScore} %`}
            </Typography>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {historicalsummaryProbability.map((stage, index) => (
                <Box key={index} sx={{ marginRight: "3px" }}>
                  <Typography variant="body2" sx={{ fontSize: "12px" ,fontWeight:700}}>
                    {stage["Growth Stage"]}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "12px",fontWeight:700 }}>
                    {stage.Percentage}%
                  </Typography>
                </Box>
              ))}
            </Box>
          </Card>
        </Grid>
        <Grid item xs={12} md={4.5}>
          <Card
            sx={{
              width: "100%",
              height: "140px",
              borderRadius: "8px",
              padding: "16px 24px",
              backgroundColor: "#FBFDFF",
              border: "0.4px solid #000",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                fontSize: "18px",
                lineHeight: "20px",
                color: "#706F6C",
              }}
            >
              Current Score
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontWeight: 700,
                fontSize: "32px",
                color:
                  averagePercentage === "NA"
                    ? "#000"
                    : averagePercentage >= 0 && averagePercentage <= 40
                    ? "red"
                    : averagePercentage >= 41 && averagePercentage <= 60
                    ? "#FFB727"
                    : averagePercentage >= 61 && averagePercentage <= 90
                    ? "#01B827"
                    : averagePercentage >= 91 && averagePercentage <= 100
                    ? "#026B13"
                    : "#000",
              }}
            >
              {averagePercentage === "NA" ? "NA" : `${averagePercentage} %`}
            </Typography>
            <Box sx={{ display: "flex", justifyContent: "space-between" }}>
              {summaryProbability?.map((stage, index) => (
                <Box key={index} sx={{ marginRight: "3px" }}>
                  <Typography variant="body2" sx={{ fontSize: "12px" ,fontWeight:700}}>
                    {stage["Growth Stage"]}
                  </Typography>
                  <Typography variant="body2" sx={{ fontSize: "12px" ,fontWeight:700}}>
                    {stage.Percentage}%
                  </Typography>
                </Box>
              ))}
            </Box>
          </Card>
        </Grid>
  
        {/* Third Card */}
        <Grid item xs={12} md={3}>
          <Card
            sx={{
              width: "100%",
              height: "140px",
              borderRadius: "8px",
              padding: "16px 10px",
              backgroundColor: "#FBFDFF",
              border: "0.4px solid #000",
            }}
          >
            <Typography
              variant="h6"
              sx={{
                fontWeight: 500,
                fontSize: "18px",
                color: "#706F6C",
              }}
            >
              Legend 
            </Typography>
  
            {/* Score Legend */}
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                marginTop: "30px",
              }}
            >
              <Box
                sx={{
                  width: "400px",
                  height: "20px",
                  display: "flex",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: "69px",
                    height: "100%",
                    backgroundColor: "red",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#FFFFFF",
                    }}
                  >
                    0-40
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "69px",
                    height: "100%",
                    backgroundColor: "#FFB727",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#FFFFFF",
                    }}
                  >
                    41-60
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "69px",
                    height: "100%",
                    backgroundColor: "#01B827",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#FFFFFF",
                    }}
                  >
                    61-90
                  </Typography>
                </Box>
                <Box
                  sx={{
                    width: "69px",
                    height: "100%",
                    backgroundColor: "#026B13",
                    position: "relative",
                  }}
                >
                  <Typography
                    variant="body2"
                    sx={{
                      position: "absolute",
                      left: "50%",
                      top: "50%",
                      transform: "translate(-50%, -50%)",
                      color: "#FFFFFF",
                    }}
                  >
                    91-100
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Card>
        </Grid>
      </Grid>
    </Box>
  
    <ToastContainer />
    <div sx={{ fontWeight: 500, fontSize: "18px", lineHeight: "20px" }}>
      Climate Data (Loan Duration)
    </div>
    <FormControl
      sx={{
        marginTop: "15px",
        width: "360px",
        height: "36px",
        marginBottom: "15px",
      }}
    >
      <InputLabel>Data Type</InputLabel>
      <Select
        value={selectedValue}
        onChange={handleSelectChange}
        label="Data Type"
        sx={{
          width: "360px",
          height: "36px",
          lineHeight: "36px",
        }}
      >
        <MenuItem value="Rainfall">Tamsat Rainfall</MenuItem>
        <MenuItem value="Soil Moisture">Volumetric SSM</MenuItem>
        <MenuItem value="Modis">ModisNDVI</MenuItem>
        <MenuItem value="sentinel">sentinel2NDVI</MenuItem>
      </Select>
    </FormControl>
  
    {/* Display graph based on selected data */}
    {selectedValue === "Rainfall" && tamsatDailyData != null && (
      <RainfallAreaRangeGraph data={tamsatDailyData} />
    )}
  
    {selectedValue === "Soil Moisture" && soilMoistureData != null && (
      <SoilMoistureAreaRangeGraph data={soilMoistureData} />
    )}
  
    {selectedValue === "Modis" && ModisNDVIData != null && (
      <VegetationAreaRangeGraph data={ModisNDVIData} />
    )}
  
    {selectedValue === "sentinel" && sentinel2NDVIData != null && (
      <VegetationAreaRangeGraph data={sentinel2NDVIData} />
    )}
  </div>
  
  );
};

export default ClimateRiskTab;
