import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';

// Initialize Highcharts modules
HighchartsMore(Highcharts);

const VegetationAreaRangeGraph = ({ data }) => {
  // Prepare the data for the area range series (min and max)
  const areaRangeData = data.map(item => [
    item.date_milliseconds, // x-value (timestamp)
    item.min,               // low (minimum vegetation)
    item.max                // high (maximum vegetation)
  ]);

  // Prepare the data for the average series
  const averageData = data.map(item => [
    item.date_milliseconds, // x-value (timestamp)
    item.average            // y-value (average vegetation)
  ]);

  const options = {
    chart: {
      zoomType: 'x', // Enables zoom on the x-axis (date range)
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: '',
      },
    },
    yAxis: {
      title: {
        text: 'Vegetation',
      },
    },
    tooltip: {
      shared: true,
      xDateFormat: '%b %e', // Show only month and day in the tooltip
    },
    credits: {
      enabled: false, // Disable Highcharts credits
    },
    series: [
      {
        name: 'Vegetation Range',
        data: areaRangeData,
        type: 'arearange', // Area between min and max
        color: 'lightblue', // Light blue color for the range
        fillOpacity: 0.3,

      },
      {
        name: 'Average Vegetation',
        data: averageData,
        type: 'line', // Average as a line
        color: 'darkblue', // Dark blue color for the average line
        lineWidth: 2, // Set the width of the average line
   
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default VegetationAreaRangeGraph;
