import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';

// Initialize Highcharts modules
HighchartsMore(Highcharts);

const RainfallAreaRangeGraph = ({ data }) => {
  // Prepare the data for the area range series (min and max)
  //console.log(data,'data')
  const areaRangeData = data.map(item => [
    item.date_milliseconds, 
    item.min,               
    item.max                
  ]);

  // Prepare the data for the average series
  const averageData = data.map(item => [
    item.date_milliseconds, 
    item.average           
  ]);

  const options = {
    chart: {
      zoomType: 'x', 
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: '',
      },
    },
    yAxis: {
      title: {
        text: 'Rainfall (mm)',
      },
    },
    tooltip: {
      shared: true,
      valueSuffix: ' mm',
      xDateFormat: '%b %e', 
    },
    credits: {
      enabled: false, 
    },
    series: [
      {
        name: 'Rainfall Range',
        data: areaRangeData,
        type: 'arearange', // Area between min and max
        color: 'lightblue', // Light blue color for the range
        fillOpacity: 0.3,
        tooltip: {
          valueSuffix: ' mm',
        },
      },
      {
        name: 'Average Rainfall',
        data: averageData,
        type: 'line', // Average as a line
        color: 'darkblue', // Dark blue color for the average line
        lineWidth: 2, // Set the width of the average line
        tooltip: {
          valueSuffix: ' mm',
        },
      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default RainfallAreaRangeGraph;
