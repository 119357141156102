import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';

// Initialize Highcharts modules
HighchartsMore(Highcharts);

const SoilMoistureAreaRangeGraph = ({ data }) => {
  // Filter out items where any value (min, max, or average) is null
  const filteredData = data.filter(
    item => item.min !== null && item.max !== null && item.average !== null
  );

  // Prepare data for area range (min and max values)
  const areaRangeData = filteredData.map(item => [
    item.date_milliseconds, 
    item.min,              
    item.max                
  ]);

  // Prepare data for average soil moisture
  const averageData = filteredData.map(item => [
    item.date_milliseconds, // x-value (timestamp)
    item.average            // y-value (average soil moisture)
  ]);

  const options = {
    chart: {
      zoomType: 'x', 
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime',
      title: {
        text: '',
      },
    },
    yAxis: {
      title: {
        text: 'Soil Moisture',
      },
    },
    tooltip: {
      shared: true,
      xDateFormat: '%b %e', 
    },
    credits: {
      enabled: false, // Disable the credits
    },
    series: [
      {
        name: 'Soil Moisture Range',
        data: areaRangeData,
        type: 'arearange', // Area between min and max
        color: 'lightblue', 
        fillOpacity: 0.3,

      },
      {
        name: 'Average Soil Moisture',
        data: averageData,
        type: 'line', 
        color: 'darkblue',
        lineWidth: 2, // Set the width of the average line

      },
    ],
  };

  return <HighchartsReact highcharts={Highcharts} options={options} />;
};

export default SoilMoistureAreaRangeGraph;
