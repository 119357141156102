import React, { useRef, useEffect, useState } from "react";
import { Tile } from "ol/layer";
import { XYZ } from "ol/source";
import * as ol from "ol";
import "ol/ol.css";
import { useMap } from "../../hooks/useMap";
import ScaleLine from "ol/control/ScaleLine";
import Geocoder from "ol-geocoder";
import "ol-geocoder/dist/ol-geocoder.min.css";
import { Vector as VectorLayer } from "ol/layer";
import { Vector as VectorSource } from "ol/source";
import "./advisorymap.css";
import { useDispatch,useSelector} from "react-redux";
import { changeLatitude, changeLongitude } from "../../../redux/action";


const AdvisoryMap = ({ children, zoom, center, getMapRef, setLatitude, setLongitude }) => {
  const { map, setMap } = useMap();
  const mapRef = useRef();
  const markerLayerRef = useRef(null);
  //const [latitude , setLatitude] = useState(null)
  //const [longitude , setLongitude]  = useState(null)
  const dispatch = useDispatch();

  const geocoder = new Geocoder("nominatim", {
    provider: "osm",
    lang: "en-US",
    placeholder: "Search for an address...",
    targetType: "text-input",
    limit: 5,
    keepOpen: false,
    autoComplete: true,
    featureStyle: () => null,
  });

  useEffect(() => {
    let xyz = new Tile({
      source: new XYZ({
        url:
          "https://api.maptiler.com/maps/streets/{z}/{x}/{y}.png?key=hDIdtmulJI4evYNmtNPG",
        tileSize: 512,
        crossOrigin: "anonymous",
      }),
      baseLayer: true,
      visible: true,
      title: "osm",
      name: "baseMapLayer",
    });

    let op = {
      view: new ol.View({
        projection: "EPSG:4326",
        zoom,
        center: center,
      }),
      controls: [
        new ScaleLine({
          units: "metric",
          bar: true,
          steps: 4,
          text: true,
          minWidth: 140,
        }),
      ],
      layers: [xyz],
    };

    let mapObj = new ol.Map(op);
    mapObj.setTarget(mapRef.current);
    setMap(mapObj);
    getMapRef(mapRef.current);

    // Create a vector source and layer to hold markers
    const markerSource = new VectorSource();
    const markerLayer = new VectorLayer({
      source: markerSource,
    });
    mapObj.addLayer(markerLayer);
    markerLayerRef.current = markerSource; // Save reference to marker source
    mapObj.addControl(geocoder);

    // Listen for geocoding results
    geocoder.on("addresschosen", (evt) => {
      const { coordinate } = evt;
      setLatitude(coordinate[0])
      setLongitude(coordinate[1])
    });

    return () => {
      mapObj.setTarget(undefined);
      setMap(null);
    };
  }, []);


  useEffect(() => {
    if (!map) return;
    map.getView().setCenter(center);
  }, [center]);

  useEffect(() => {
    if (!map) return;
    map.getView().animate({ zoom: zoom, duration: 1000 });
  }, [zoom]);

  return (
    <div ref={mapRef} style={{ position: "relative", inset: 0, height: "100%" }}>
      {children}
    </div>
  );
};

export default AdvisoryMap;
