const awsmobile = {
  "aws_project_region": "ap-south-1",
  "aws_cognito_identity_pool_id": "ap-south-1:5c03897a-52c4-4a81-ab0f-d86b2183bdd2",
  "aws_cognito_region": "ap-south-1",
  "aws_user_pools_id": "ap-south-1_5B1Uarc3j",
  "aws_user_pools_web_client_id": "3h66jjsdq6ij0pc5h8ip6a67gn",
  "oauth": {},
  // "aws_mobile_analytics_app_region": "ap-south-1",
  // "aws_mobile_analytics_app_id": "9b7ffa0f54f74ef7856b33d0daa48014"
};
export default awsmobile;
