import React, { useState } from "react";
import { Amplify } from "aws-amplify";
import "@aws-amplify/ui-react/styles.css";
import awsmobile from "./aws-exports";
import { withAuthenticator } from "@aws-amplify/ui-react";
import MainLayout from "./components/MainLayout";
import MainScreen from "./components/MainScreen";
import OutputPortfolio from "./components/OutputPortfolio";
import OutputIndividual from "./components/OutputIndividual";
import CalculateRiskMain from "./components/CalculateRiskMain";
import Monitor from "./components/Monitor";
import Advisory from "./components/Advisory";
import LandingPage from "./components/LandingPage";
import AdminMain from "./components/AdminProfile/AdminMain";
import Settings from "./components/AdminProfile/Settings";
import AddUserRole from "./components/AdminProfile/AddUserRole";
import AddUserGroup from "./components/AdminProfile/AddUserGroup";
import PasswordSettings from "./components/AdminProfile/PasswordSettings";
Amplify.configure(awsmobile);

function App() {
  const [outputportfolioscreen, setoutputportfolioscreen] = useState(false);
  const [riskscreen, setriskscreen] = useState(false);
  const [landingscreen, setlandingscreen] = useState(true);
  const [mainscreen, setMainscreen] = useState(false);
  const [monitorscreen, setmonitorscreen] = useState(false);
  const [advisorycreen, setadvisorycreen] = useState(false);
  const [calulateriskmainscreen, setcalulateriskmainscreen] = useState(false);
  const [adminProfilescreen, setadminProfilescreen] = useState(false);
  const [adminSettingsScreen, setadminSettingsScreen] = useState(false);
  const [addUserRoleScreen, setaddUserRoleScreen] = useState(false);
  const [addUserGroupScreen, setaddUserGroupScreen] = useState(false);
  const [PasswordSettingscreen, setPasswordSettingscreen] = useState(false);
  const [selectedUserRoleRow, setSelectedUserRoleRow] = useState(null);

  // const [email, setEmail] = useState('');
  return (
    <div className="App">
      {landingscreen && (
        <LandingPage
          outputportfolioscreen={outputportfolioscreen}
          setoutputportfolioscreen={setoutputportfolioscreen}
          setlandingscreen={setlandingscreen}
        />
      )}
      {outputportfolioscreen && (
        <OutputPortfolio
          mainscreen={mainscreen}
          setMainscreen={setMainscreen}
          setoutputportfolioscreen={setoutputportfolioscreen}
          setcalulateriskmainscreen={setcalulateriskmainscreen}
          calulateriskmainscreen={calulateriskmainscreen}
          setmonitorscreen={setmonitorscreen}
          advisorycreen={advisorycreen}
          setadvisorycreen={setadvisorycreen}
          adminProfilescreen={adminProfilescreen}
          setadminProfilescreen={setadminProfilescreen}
          setPasswordSettingscreen={setPasswordSettingscreen}
          
        />
      )}
      {monitorscreen && (
        <Monitor
          setmonitorscreen={setmonitorscreen}
          setMainscreen={setMainscreen}
          riskscreen={riskscreen}
          setriskscreen={setriskscreen}
          setcalulateriskmainscreen={setcalulateriskmainscreen}
          setoutputportfolioscreen={setoutputportfolioscreen}
          advisorycreen={advisorycreen}
          setadvisorycreen={setadvisorycreen}
          adminProfilescreen={adminProfilescreen}
          setadminProfilescreen={setadminProfilescreen}
          setPasswordSettingscreen={setPasswordSettingscreen}

        />
      )}
      {mainscreen && (
        <MainScreen
          setmonitorscreen={setmonitorscreen}
          setcalulateriskmainscreen={setcalulateriskmainscreen}
          setMainscreen={setMainscreen}
          riskscreen={riskscreen}
          setriskscreen={setriskscreen}
          setoutputportfolioscreen={setoutputportfolioscreen}
          advisorycreen={advisorycreen}
          setadvisorycreen={setadvisorycreen}
          adminProfilescreen={adminProfilescreen}
          setadminProfilescreen={setadminProfilescreen}
          setPasswordSettingscreen={setPasswordSettingscreen}

        />
      )}
      {calulateriskmainscreen && (
        <CalculateRiskMain
          setmonitorscreen={setmonitorscreen}
          setcalulateriskmainscreen={setcalulateriskmainscreen}
          setMainscreen={setMainscreen}
          riskscreen={riskscreen}
          setriskscreen={setriskscreen}
          setoutputportfolioscreen={setoutputportfolioscreen}
          advisorycreen={advisorycreen}
          setadvisorycreen={setadvisorycreen}
          adminProfilescreen={adminProfilescreen}
          setadminProfilescreen={setadminProfilescreen}
          setPasswordSettingscreen={setPasswordSettingscreen}

        />
      )}
      {riskscreen && (
        <OutputIndividual
          setcalulateriskmainscreen={setcalulateriskmainscreen}
          mainscreen={mainscreen}
          setmonitorscreen={setmonitorscreen}
          setMainscreen={setMainscreen}
          setriskscreen={setriskscreen}
          setoutputportfolioscreen={setoutputportfolioscreen}
          advisorycreen={advisorycreen}
          setadvisorycreen={setadvisorycreen}
          adminProfilescreen={adminProfilescreen}
          setadminProfilescreen={setadminProfilescreen}
          setPasswordSettingscreen={setPasswordSettingscreen}

        />
      )}
      {advisorycreen && (
        <Advisory
          setcalulateriskmainscreen={setcalulateriskmainscreen}
          mainscreen={mainscreen}
          setmonitorscreen={setmonitorscreen}
          setMainscreen={setMainscreen}
          setriskscreen={setriskscreen}
          setoutputportfolioscreen={setoutputportfolioscreen}
          advisorycreen={advisorycreen}
          setadvisorycreen={setadvisorycreen}
          adminProfilescreen={adminProfilescreen}
          setadminProfilescreen={setadminProfilescreen}
          setPasswordSettingscreen={setPasswordSettingscreen}

        />
      )}
      {adminProfilescreen && (
        <AdminMain
          setcalulateriskmainscreen={setcalulateriskmainscreen}
          mainscreen={mainscreen}
          setmonitorscreen={setmonitorscreen}
          setMainscreen={setMainscreen}
          setriskscreen={setriskscreen}
          setoutputportfolioscreen={setoutputportfolioscreen}
          advisorycreen={advisorycreen}
          setadvisorycreen={setadvisorycreen}
          setadminProfilescreen={setadminProfilescreen}
          setadminSettingsScreen={setadminSettingsScreen}
          setPasswordSettingscreen={setPasswordSettingscreen}

        />
      )}
      {adminSettingsScreen && (
        <Settings
          setcalulateriskmainscreen={setcalulateriskmainscreen}
          mainscreen={mainscreen}
          setmonitorscreen={setmonitorscreen}
          setMainscreen={setMainscreen}
          setriskscreen={setriskscreen}
          setoutputportfolioscreen={setoutputportfolioscreen}
          advisorycreen={advisorycreen}
          setadvisorycreen={setadvisorycreen}
          setadminProfilescreen={setadminProfilescreen}
          setadminSettingsScreen={setadminSettingsScreen}
          setaddUserRoleScreen={setaddUserRoleScreen}
          setaddUserGroupScreen={setaddUserGroupScreen}
          setPasswordSettingscreen={setPasswordSettingscreen}
          setSelectedUserRoleRow={setSelectedUserRoleRow}

        />
      )}

      {addUserRoleScreen && (
        <AddUserRole
          setcalulateriskmainscreen={setcalulateriskmainscreen}
          mainscreen={mainscreen}
          setmonitorscreen={setmonitorscreen}
          setMainscreen={setMainscreen}
          setriskscreen={setriskscreen}
          setoutputportfolioscreen={setoutputportfolioscreen}
          advisorycreen={advisorycreen}
          setadvisorycreen={setadvisorycreen}
          setadminProfilescreen={setadminProfilescreen}
          setadminSettingsScreen={setadminSettingsScreen}
          setaddUserRoleScreen={setaddUserRoleScreen}
          selectedUserRoleRow={selectedUserRoleRow}
        />
      )}

      {addUserGroupScreen && (
        <AddUserGroup
          setcalulateriskmainscreen={setcalulateriskmainscreen}
          mainscreen={mainscreen}
          setmonitorscreen={setmonitorscreen}
          setMainscreen={setMainscreen}
          setriskscreen={setriskscreen}
          setoutputportfolioscreen={setoutputportfolioscreen}
          advisorycreen={advisorycreen}
          setadvisorycreen={setadvisorycreen}
          setadminProfilescreen={setadminProfilescreen}
          setadminSettingsScreen={setadminSettingsScreen}
          setaddUserGroupScreen={setaddUserGroupScreen}
          setaddUserRoleScreen={setaddUserRoleScreen}
        />
      )}
      {PasswordSettingscreen && (
        <PasswordSettings
          setcalulateriskmainscreen={setcalulateriskmainscreen}
          mainscreen={mainscreen}
          setmonitorscreen={setmonitorscreen}
          setMainscreen={setMainscreen}
          setriskscreen={setriskscreen}
          setoutputportfolioscreen={setoutputportfolioscreen}
          advisorycreen={advisorycreen}
          setadvisorycreen={setadvisorycreen}
          setadminProfilescreen={setadminProfilescreen}
          setadminSettingsScreen={setadminSettingsScreen}
          setaddUserGroupScreen={setaddUserGroupScreen}
          setaddUserRoleScreen={setaddUserRoleScreen}
          setPasswordSettingscreen={setPasswordSettingscreen}

        />
      )}
    </div>
  );
}
export default App;
//export default withAuthenticator(App);
