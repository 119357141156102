import React, { useState, useEffect } from 'react';
import { Button } from '@mui/material';
import { useSelector } from "react-redux";
import { Auth } from "aws-amplify";

const CropSummary = (props) => {
  const productionDateSelected = useSelector((state) => state.productionDateSelected);
  const rankedCropName = useSelector((state) => state.rankedCropName);
  const cropNameSelected = useSelector((state) => state.cropNameSelected);
  const cropScoreSelected = useSelector((state) => state.cropScoreSelected);
  const [summaryData, setSummaryData] = useState([]);

  useEffect(() => {
    const fetchCropSummary = async () => {
      try {
        const {
          accessToken: { jwtToken },
        } = await Auth.currentSession();
        if (cropNameSelected && cropScoreSelected && productionDateSelected) {
          const response = await fetch(
            `/api/homepage/generateCropSummary?cropNameSelected=${cropNameSelected}&cropScoreSelected=${cropScoreSelected}&productionDateSelected=${productionDateSelected}&costOfInputs=${props.costOfInputs}&totalLandCover=${props.totalLandCover}&totalCost=${props.totalCost}`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: jwtToken,
              },
            }
          );
          
          if (!response.ok) {
            throw new Error('Failed to fetch crop summary');
          }
  
          const data = await response.json();
          setSummaryData(data);
        }
      } catch (error) {
        console.error('Error fetching crop summary:', error);
      }
    };
  
    if (props.showcropSummary) {
      fetchCropSummary();
    }
  }, [props.showcropSummary, cropNameSelected, cropScoreSelected, productionDateSelected, props.costOfInputs, props.totalLandCover, props.totalCost]);
  
  const handleDownload = () => {
    const csvContent = summaryData.map(item => `${item.label},${item.value}`).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.setAttribute('download', 'crop_summary.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <div style={{ padding: '20px' }}>
      <div style={{ display: 'flex', marginBottom: '20px' }}>
        {/* First column */}
        <div style={{ flex: '1 1 1', marginRight: '10%' }}>
          {summaryData.slice(0, 3).map((item, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>{item.label}</div>
              <div>{item.value}</div>
            </div>
          ))}
        </div>
        {/* Second column */}
        <div style={{ flex: '1 1 1', marginRight: '10%' }}>
          {summaryData.slice(3, 6).map((item, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>{item.label}</div>
              <div>{item.value}</div>
            </div>
          ))}
        </div>
        {/* Third column */}
        <div style={{ flex: '1 1 1' }}>
          {summaryData.slice(6, 8).map((item, index) => (
            <div key={index} style={{ marginBottom: '10px' }}>
              <div style={{ fontWeight: 'bold' }}>{item.label}</div>
              <div>{item.value}</div>
            </div>
          ))}
          <div style={{ marginTop: '20px' }}>
            <Button
              variant="contained"
              onClick={handleDownload}
              style={{
                width: '100%',
                padding: '11px',
                borderRadius: '25px',
                background: '#F5821F',
                color: '#FFF'
              }}
            >
              Download
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CropSummary;
