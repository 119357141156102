import React, { useState} from "react";
import "../../mainScreen.css";
import { FaUserAlt } from "react-icons/fa";
import { IoMdHome, IoMdMenu } from "react-icons/io";
import { IoIosSettings, IoIosArrowForward } from "react-icons/io";
import { Auth } from "aws-amplify";
import { MdOutlineKey, MdOutlineRemoveRedEye } from "react-icons/md";
import { IoLogOutOutline } from "react-icons/io5";
import { FaCircleUser } from "react-icons/fa6";
import { IoSettingsOutline } from "react-icons/io5";
import { IoIosArrowBack } from "react-icons/io";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
  Select,
  MenuItem,
  TextField,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const AddUser = (props) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [userName, setUserName] = useState(""); // State for user name
  const [email, setEmail] = useState(""); // State for email
  const [group, setGroup] = useState(""); // State for group dropdown
  const [userRole, setUserRole] = useState(""); // State for user role dropdown
  const LoginEmail = useSelector((state) => state.LoginEmail);
  const enterprise = useSelector((state) => state.enterprise);

  const [accessControl, setAccessControl] = useState({
    Home: [],
    Calculate: [],
    Explore: [],
    Monitor: [],
    Advisory: [],
  });


  
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleMenuToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  // Update the handleSave function to use dynamic values
  const handleSave = async () => {
    try {
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      const adminEmail = LoginEmail; // Get current admin email dynamically
      //console.log(adminEmail,LoginEmail)
      const response = await fetch("/api/admin/createAccount", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
           Authorization: jwtToken,
        },
        body: JSON.stringify({
          user_name: userName, // User name from the input field
          email: email, // Email from the input field
          group_name: group, // Group from the dropdown
          user_role: userRole, // User role from the dropdown
          admin_email: LoginEmail, // Admin email fetched dynamically
          enterprise:enterprise
        }),
      });

      // Check if the response indicates success
      if (response.ok) {
        toast.success("Account created successfully!");
      } else {
        toast.error("Failed to create account. Please try again.");
      }
    } catch (error) {
      // Error handling
      toast.error(`Error: ${error.message}`);
    }
  };

  const handleCheckboxChange = (func, type) => {
    setAccessControl((prev) => {
      const currentAccess = prev[func] || [];
      // Toggle Read or Write
      const updatedAccess = currentAccess.includes(type)
        ? currentAccess.filter((item) => item !== type)
        : [...currentAccess, type];

      return { ...prev, [func]: updatedAccess };
    });
  };

  return (
    <div className="main-screen" style={{ display: "flex" }}>
      {/* Sidebar */}
      <div
        style={{
          background: "#00253D",
          height: "100vh",
          zIndex: "1001",
          position: "relative",
          width: isCollapsed ? "80px" : "auto", // Adjust width for collapsed state
          transition: "width 0.3s",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "100%",
            }}
          >
            <IoMdMenu color="white" size={28} onClick={handleMenuToggle} />
            {!isCollapsed && (
              <span
                style={{ marginLeft: "10px", color: "white", fontSize: "22px" }}
              >
                TARA
              </span>
            )}
          </div>

          <div
            className="custom-buttonnav"
            style={{
              padding: "20px",
              display: "flex",
              cursor: "pointer",
              background: "#F5821F",
            }}
          >
            <FaUserAlt
              color="white"
              size={28}
              style={{ marginRight: isCollapsed ? "0" : "10px" }}
            />
            {!isCollapsed && (
              <span style={{ color: "white", fontSize: "18px" }}>
                User Management
              </span>
            )}
          </div>

          <div
            className="custom-buttonnav"
            style={{ padding: "20px", display: "flex", cursor: "pointer" }}
            onClick={() => {
              props.setadminSettingsScreen(true);
              props.setadminProfilescreen(false);
              props.setadvisorycreen(false);
              props.setmonitorscreen(false);
              props.setMainscreen(false);
              props.setcalulateriskmainscreen(false);
              props.setoutputportfolioscreen(false);
            }}
          >
            <IoIosSettings
              color="white"
              size={28}
              style={{ marginRight: isCollapsed ? "0" : "10px" }}
            />
            {!isCollapsed && (
              <span style={{ color: "white", fontSize: "18px" }}>
                User Settings
              </span>
            )}
          </div>
        </div>

        <div style={{ position: "relative", width: "100%" }}>
          <div
            className="custom-buttonnav"
            style={{
              padding: "15px",
              display: "flex",
              width: "auto",
              cursor: "pointer",
              alignItems: "center",
              borderTop: "2px solid white",
              position: "relative",
            }}
            onClick={togglePopup}
          >
            {showPopup && (
              <div
                style={{
                  position: "absolute",
                  top: "-110px",
                  left: "10px",
                  backgroundColor: "white",
                  color: "black",
                  padding: "10px",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: "1000",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    props.setadminProfilescreen(false);
                    props.setadvisorycreen(false);
                    props.setmonitorscreen(false);
                    props.setMainscreen(false);
                    props.setcalulateriskmainscreen(false);
                    props.setoutputportfolioscreen(true);
                  }}
                >
                  <IoSettingsOutline style={{ marginRight: "8px" }} /> Switch to User
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",
                    cursor: "pointer",
                  }}
                >
                  <IoSettingsOutline style={{ marginRight: "8px" }} /> Settings
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",
                    cursor: "pointer",
                  }}
                  onClick={handleLogout}
                >
                  <IoLogOutOutline style={{ marginRight: "8px" }} /> Logout
                </div>
              </div>
            )}

            <FaCircleUser
              color="white"
              size={25}
              style={{ marginRight: isCollapsed ? "0" : "8px" }}
            />
            {!isCollapsed && (
              <>
                <span
                  style={{
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  {LoginEmail}
                </span>
                <IoIosArrowForward color="white" size={20} />
              </>
            )}
          </div>
        </div>
      </div>

      <div
        style={{
          flex: 1,
          padding: "20px",
          overflowY: "auto",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          {/* Back Icon */}
          <button
            onClick={() => props.setaddUserScreen(false)}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              marginRight: "10px",
            }}
          >
            <IoIosArrowBack size={25} />
          </button>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <h1 style={{ fontSize: "28px", fontWeight: "bold" }}>Add User</h1>
          </div>
        </div>

        <div
          style={{
            border: "1px solid #D3D2CE",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <h2>User Details</h2>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            {/* User Name */}
            <div style={{ flex: 1, marginRight: "10px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: 700,
                }}
              >
                User Name
              </label>
              <input
                type="text"
                value={userName}
                onChange={(e) => setUserName(e.target.value)} // Handle user name input
                placeholder="Enter user name"
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            {/* Email Address */}
            <div style={{ flex: 1, marginRight: "10px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: 700,
                }}
              >
                Email Address
              </label>
              <input
                type="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)} // Handle email input
                placeholder="Enter email address"
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
              />
            </div>

            {/* User Role */}
            <div style={{ flex: 1, marginRight: "10px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: 700,
                }}
              >
                User Role
              </label>
              <Select
                value={userRole}
                onChange={(e) => setUserRole(e.target.value)} // Handle user role selection
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return (
                      <span style={{ color: "#aaa" }}>Select user role</span>
                    );
                  }
                  return selected;
                }}
                style={{
                  width: "100%",
                  height: "46px",
                  padding: "0 10px",
                  fontSize: "16px",
                  borderRadius: "5px",
                }}
              >
                <MenuItem value="UnderWriter">UnderWriter</MenuItem>
                <MenuItem value="Developer">Developer</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
              </Select>
            </div>

            {/* Group */}
            <div style={{ flex: 1 }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: 700,
                }}
              >
                User Group
              </label>
              <Select
                value={group}
                onChange={(e) => setGroup(e.target.value)} // Handle group selection
                displayEmpty
                renderValue={(selected) => {
                  if (!selected) {
                    return <span style={{ color: "#aaa" }}>Select group</span>;
                  }
                  return selected === "user" ? "User" : "Admin";
                }}
                style={{
                  width: "100%",
                  height: "46px",
                  padding: "0 10px",
                  fontSize: "16px",
                  borderRadius: "5px",
                }}
              >
                <MenuItem value="user">User</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
              </Select>
            </div>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              style={{
                color: "#00253D",
                marginRight: "10px",
                background: "none",
                width: "170px",
              }}
              onClick={() => {
                props.setadminProfilescreen(true);
                props.setaddUserScreen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#00253D",
                width: "170px",
                color: "white",
              }}
              onClick={handleSave}
            >
              Save
            </Button>

            <ToastContainer />
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default AddUser;
