import React from 'react';
import '../mainScreen.css';
import { useSelector, useDispatch } from "react-redux";
import ActivitiesTable from './ActivitiesTable';
import AIAdvisoryTable from './AIAdvisoryTable';
import MapViewAnalyticsAdvisory from './Mapping/MapViewAnalyticsAdvisory';
import { changeTableAdvisoryData } from '../redux/action';

const AdvisoryRightPanel = (props) => {
  const tableAdvisoryData = useSelector((state)=>state.tableAdvisoryData);
  const dispatch = useDispatch()

  return (
    <div className="advisoryright-panel">
          <div>
            <h2 style={{ backgroundColor: '#00253D', padding: '10px', color: '#fff', borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}>
              Select a point
            </h2>
            <div style={{ height: '60vh', display: tableAdvisoryData.length === 0 ?'grid':'none'}}>
              <MapViewAnalyticsAdvisory
                featureData={props.featureData}
                selectedState={props.selectedState}
                selectedConstituency={props.selectedConstituency}
                setLatitude={(e) => { props.setLatitude(e) }}
                setLongitude={(e) => { props.setLongitude(e) }}
              />
            </div>
            <p style={{textAlign:"center",margin:'20px 10px 10px 10px',display: tableAdvisoryData.length === 0 ?'grid':'none'}}>Single click to go one level down further and double click to add a pointer</p>
          </div>
     
      {/* Adding a dotted horizontal rule */}
      {tableAdvisoryData.length > 0?
      (<hr style={{ border: 'none', borderTop: '1px dotted #ccc', margin: '10px 0' }} />):("")}
      {tableAdvisoryData.length > 0 && props.defaultscreen === true &&
        <div>
          <div className="custom-button" style={{ width: '20%', backgroundColor: '#91B187',margin:'0 20px' }} onClick={()=>{dispatch(changeTableAdvisoryData([]));props.setMap()}}>
           Back to map
          </div>
          <ActivitiesTable activities={props.DefaultAdvisory} />
        </div>
      }
      {props.defaultscreen === true && props.DefaultAdvisory.length === 0 &&
        <div style={{ textAlign: 'center', padding: '20px' }}>{props.AdvisoryError}</div>
      }
      {props.AIpoweredscreen === true &&
        <AIAdvisoryTable aiadvisorydata={props.AIadvisory} />
      }

    </div>
  );
};

export default AdvisoryRightPanel;
