import React, { useState, useEffect } from "react";
import "../../mainScreen.css";
import { FaUserAlt } from "react-icons/fa";
import { IoMdMenu, IoIosSettings, IoIosArrowForward, IoIosArrowBack } from "react-icons/io";
import { Auth } from "aws-amplify";
import { MdOutlineKey, MdOutlineRemoveRedEye } from "react-icons/md";
import { IoLogOutOutline, IoSettingsOutline } from "react-icons/io5";
import { FaCircleUser } from "react-icons/fa6";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Checkbox,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useSelector } from "react-redux";

const AddUserRole = (props) => {
  const { selectedUserRoleRow } = props; // Destructure the selected row from props
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [userRole, setUserRole] = useState(""); // State for user role input
  const [accessControl, setAccessControl] = useState({
    Home: [],
    Calculate: [],
    Explore: [],
    Monitor: [],
    Advisory: [],
  });
  const LoginEmail = useSelector((state) => state.LoginEmail);
  const enterprise = useSelector((state) => state.enterprise);


  useEffect(() => {
    if (selectedUserRoleRow) {
      // Prepopulate user role
      setUserRole(selectedUserRoleRow.Role);
  
      // Prepopulate access control by parsing functionality
      const updatedAccessControl = {
        Home: [],
        Calculate: [],
        Explore: [],
        Monitor: [],
        Advisory: [],
      };
  
      const functionalities = selectedUserRoleRow.functionality.split(";");
      functionalities.forEach((func) => {
        // Split using ":". This removes any assumption about spaces around ":"
        const [accessType, funcList] = func.split(":");
        
        // Make sure funcList exists and trim extra spaces
        if (funcList) {
          const functions = funcList.split(",");
          functions.forEach((f) => {
            const trimmedFunction = f.trim();
            if (accessType.trim() === "R") {
              updatedAccessControl[trimmedFunction].push("Read");
            } else if (accessType.trim() === "W") {
              updatedAccessControl[trimmedFunction].push("Write");
            }
          });
        }
      });
  
      setAccessControl(updatedAccessControl);
    }
  }, [selectedUserRoleRow]);
  
  const togglePopup = () => {
    setShowPopup(!showPopup);
  };

  const handleMenuToggle = () => {
    setIsCollapsed(!isCollapsed);
  };

  const toggleDrawer = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      window.location.reload();
    } catch (error) {
      console.log("error signing out: ", error);
    }
  };

  const handleCheckboxChange = (func, type) => {
    setAccessControl((prev) => {
      const currentAccess = prev[func] || [];
      // Toggle Read or Write
      const updatedAccess = currentAccess.includes(type)
        ? currentAccess.filter((item) => item !== type)
        : [...currentAccess, type];

      return { ...prev, [func]: updatedAccess };
    });
  };

  const saveUserRole = async () => {
    // Prepare API payload
    const payload = {
      user_role: userRole,
      permissions: accessControl,
      admin_email: LoginEmail, // Admin email fetched dynamically
      enterprise:enterprise
    };

    try {
      // Make the API call
      const { accessToken: { jwtToken } } = await Auth.currentSession();
      const response = await fetch("/api/admin/addModifyUserRole", {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        toast.success("User role saved successfully!");
      } else {
        const errorData = await response.json();
        toast.error(`Failed to save user role`);
      }
    } catch (error) {
      toast.error(`Failed to save user role`);
    }
  };

  return (
    <div className="main-screen" style={{ display: "flex" }}>
      <div
        style={{
          background: "#00253D",
          height: "100vh",
          zIndex: "1001",
          position: "relative",
          width: isCollapsed ? "80px" : "auto", // Adjust width for collapsed state
          transition: "width 0.3s",
          alignItems: "flex-start",
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <div>
          <div
            style={{
              padding: "20px",
              display: "flex",
              alignItems: "center",
              cursor: "pointer",
              width: "100%",
            }}
          >
            <IoMdMenu color="white" size={28} onClick={handleMenuToggle} />
            {!isCollapsed && (
              <span
                style={{ marginLeft: "10px", color: "white", fontSize: "22px" }}
              >
                TARA
              </span>
            )}
          </div>

          <div
            className="custom-buttonnav"
            style={{
              padding: "20px",
              display: "flex",
              cursor: "pointer",
            }}
            onClick={() => {
              props.setadminProfilescreen(true);
              props.setaddUserRoleScreen(false);
              props.setadminSettingsScreen(false);
              props.setoutputportfolioscreen(false);
            }}
          >
            <FaUserAlt
              color="white"
              size={28}
              style={{ marginRight: isCollapsed ? "0" : "10px" }}
            />
            {!isCollapsed && (
              <span style={{ color: "white", fontSize: "18px" }}>
                User Management
              </span>
            )}
          </div>

          <div
            className="custom-buttonnav"
            style={{
              padding: "20px",
              display: "flex",
              cursor: "pointer",
              background: "#F5821F",
            }}
          >
            <IoIosSettings
              color="white"
              size={28}
              style={{ marginRight: isCollapsed ? "0" : "10px" }}
            />
            {!isCollapsed && (
              <span style={{ color: "white", fontSize: "18px" }}>
                User Settings
              </span>
            )}
          </div>
        </div>

        <div style={{ position: "relative", width: "100%" }}>
          <div
            className="custom-buttonnav"
            style={{
              padding: "15px",
              display: "flex",
              width: "auto",
              cursor: "pointer",
              alignItems: "center",
              borderTop: "2px solid white",
              position: "relative",
            }}
            onClick={togglePopup}
          >
            {showPopup && (
              <div
                style={{
                  position: "absolute",
                  top: "-110px",
                  left: "10px",
                  backgroundColor: "white",
                  color: "black",
                  padding: "10px",
                  borderRadius: "5px",
                  boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                  zIndex: "1000",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    props.setadminProfilescreen(false);
                    props.setadvisorycreen(false);
                    props.setmonitorscreen(false);
                    props.setMainscreen(false);
                    props.setcalulateriskmainscreen(false);
                    props.setoutputportfolioscreen(true);
                  }}
                >
                  <IoSettingsOutline style={{ marginRight: "8px" }} /> Switch as
                  user
                </div>
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",
                    cursor: "pointer",
                  }}
                >
                  <IoSettingsOutline style={{ marginRight: "8px" }} /> Settings
                </div>

                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    padding: "5px 0",
                    cursor: "pointer",
                  }}
                  onClick={handleLogout}
                >
                  <IoLogOutOutline style={{ marginRight: "8px" }} /> Logout
                </div>
              </div>
            )}

            <FaCircleUser
              color="white"
              size={25}
              style={{ marginRight: isCollapsed ? "0" : "8px" }}
            />
            {!isCollapsed && (
              <>
                <span
                  style={{
                    color: "white",
                    fontSize: "12px",
                  }}
                >
                  {LoginEmail}
                </span>
                <IoIosArrowForward color="white" size={20} />
              </>
            )}
          </div>
        </div>
      </div>
      <div style={{ flex: 1, padding: "20px", overflowY: "auto" }}>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            marginBottom: "20px",
          }}
        >
          <button
            onClick={() => {
              props.setaddUserRoleScreen(false);
              props.setadminSettingsScreen(true);
            }}
            style={{
              background: "none",
              border: "none",
              cursor: "pointer",
              marginRight: "10px",
            }}
          >
            <IoIosArrowBack size={25} />
          </button>
          <h1 style={{ fontSize: "28px", fontWeight: "bold" }}>Add User Role</h1>
        </div>

        <div
          style={{
            border: "1px solid #D3D2CE",
            padding: "20px",
            borderRadius: "5px",
          }}
        >
          <h2>User role Details</h2>
          <div
            style={{
              display: "flex",
              marginTop: "20px",
              marginBottom: "20px",
            }}
          >
            {/* User Role */}
            <div style={{ marginRight: "10px", width: "380px" }}>
              <label
                style={{
                  display: "block",
                  marginBottom: "5px",
                  fontWeight: 700,
                }}
              >
                User Role
              </label>
              <input
                type="text"
                placeholder="Enter user name"
                style={{
                  width: "100%",
                  padding: "10px",
                  fontSize: "16px",
                  borderRadius: "5px",
                  border: "1px solid #ccc",
                }}
                value={userRole}
                onChange={(e) => setUserRole(e.target.value)}
              />
            </div>
          </div>

          <div style={{ marginBottom: "20px" }}>
            <label
              style={{ display: "block", marginBottom: "5px", fontWeight: 700 }}
            >
              Access Control
            </label>
            <TableContainer component={Paper}>
              <Table>
                <TableHead
                  style={{ backgroundColor: "#F5821F", fontWeight: "700" }}
                >
                  <TableRow>
                    <TableCell style={{ color: "white", fontWeight: "700" }}>
                      Functionality
                    </TableCell>
                    <TableCell style={{ color: "white", fontWeight: "700" }}>
                      View Access
                    </TableCell>
                    <TableCell style={{ color: "white", fontWeight: "700" }}>
                      Edit Access
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {["Home", "Explore", "Calculate", "Monitor", "Advisory"].map(
                    (func) => (
                      <TableRow key={func}>
                        <TableCell>{func}</TableCell>
                        <TableCell>
                          <Checkbox
                            checked={accessControl[func].includes("Read")}
                            onChange={() => handleCheckboxChange(func, "Read")}
                          />
                        </TableCell>
                        <TableCell>
                          <Checkbox
                            checked={accessControl[func].includes("Write")}
                            onChange={() => handleCheckboxChange(func,"Write")}
                          />
                        </TableCell>
                      </TableRow>
                    )
                  )}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginTop: "20px",
            }}
          >
            <Button
              variant="contained"
              style={{
                color: "#00253D",
                marginRight: "10px",
                background: "none",
                width: "170px",
              }}
              onClick={() => {
                props.setadminSettingsScreen(true);
                props.setaddUserRoleScreen(false)
              }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              style={{
                backgroundColor: "#00253D",
                width: "170px",
                color: "white",
              }}
              onClick={saveUserRole}
            >
              Save
            </Button>
            <ToastContainer />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddUserRole;
