import { Style, Stroke, Fill, Icon} from "ol/style";
import GeoJSON from "ol/format/GeoJSON";
import { MyLayers, VectorLayer } from "./Layers";
import AdvisoryMap from "./Map/AdvisoryMap";
import { vector, wms } from "./Source";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { changeZoom } from "../../redux/action";
import DrawOnMap from "./Draw/DrawOnMap";
import { useMap } from "../hooks/useMap";
import { toStringHDMS } from "ol/coordinate";
import VectorSource from "ol/source/Vector";
import VectorLayerOL from "ol/layer/Vector";
import TileLayer from "./Layers/TileLayer";
import { getTransparentColor } from "../lib/gis";
import { Feature } from "ol";
import { Point } from "ol/geom";
import { fromLonLat } from "ol/proj";




function MapViewAnalyticsAdvisory({ featureData, selectedState, selectedConstituency, setLatitude, setLongitude }) {
  const { map, fishnetLayer } = useMap();
  const [center, setCenter] = useState([32.290275, 1.373333]);
  const [drawIsActive, setDrawIsActive] = useState(false);
  const [coords, setCoords] = useState();
  const [mapRef, setMapRef] = useState();
  const [markerLayer, setMarkerLayer] = useState(null);
  const dispatch = useDispatch();
  const zoom = useSelector((state) => state.zoom);

 

 
  useEffect(() => {
    if (!map) return;
    dispatch(changeZoom(6));
    map.on("pointermove", function (event) {
      if (event.coordinate) {
        const coordinates = toStringHDMS(event.coordinate);
        setCoords(coordinates);
      } else {
        setCoords();
      }
    });

    map.on("singleclick", function (event) {
      if(event.coordinate.length > 1){
      setLatitude(event.coordinate[0])
      setLongitude(event.coordinate[1])
      map.removeLayer(
        map
          .getLayers()
          .getArray()
          .find((layer) => layer.get("name") === "highlightLayer")
      );
      }
      
    });

    map.on("dblclick", function (event) {
      if(event.coordinate.length > 1){
      setLatitude(event.coordinate[0])
      setLongitude(event.coordinate[1])
      map.removeLayer(
        map
          .getLayers()
          .getArray()
          .find((layer) => layer.get("name") === "highlightLayer")
      );
      }
       // Clear previous markers
      markerLayer.getSource().clear();
      const marker = new Feature({
        geometry: new Point(fromLonLat([event.coordinate[0],event.coordinate[1]])),
      });
      marker.getGeometry().transform("EPSG:3857", "EPSG:4326");
      marker.setStyle(
        new Style({
          image: new Icon({
            src: "/location-pin.png",
          }),
        })
      );
      markerLayer.getSource().addFeature(marker);
      map
        .getView()
        .animate({ center:[event.coordinate[0],event.coordinate[1]], duration: 1000 });

    });

    // Initialize marker layer
    const markerSource = new VectorSource();
    const markerLayer = new VectorLayerOL({
      source: markerSource,
    });
    map.addLayer(markerLayer);
    setMarkerLayer(markerLayer);

    return () => {
      map.removeLayer(markerLayer);
    };

   


  }, [map]);


 
  return (
    <>

      <AdvisoryMap zoom={zoom} center={center} getMapRef={setMapRef} setLatitude={(e)=>setLatitude(e)} setLongitude={(e)=>setLongitude(e)}>
        <MyLayers>
          <DrawOnMap type="Polygon" isActive={drawIsActive} />
          {featureData !== "" && featureData &&
            featureData.features.length > 0
            ? (
              <VectorLayer
                zoomTo
                data={featureData}
                source={vector({
                  features: new GeoJSON()
                    .readFeatures(featureData)
                    .filter((x) => x.getGeometry() != null),
                })}
                style={(feature) => {
                  if (selectedConstituency == "")
                    return getTransparentColor(feature, selectedState);
                  else
                    return getTransparentColor(feature, selectedConstituency);
                }}
                name="AdministrativeBoundary"
                zIndex={10}
              />
            ) : ('')}
          <VectorLayer
            source={vector({
              features: new GeoJSON().readFeatures({
                type: "FeatureCollection",
                features: [],
              }),
            })}
            style={
              new Style({
                stroke: new Stroke({
                  color: "yellow",
                  width: 3,
                }),
                fill: new Fill({
                  color: "rgba(0, 0, 255, 0.1)",
                }),
              })
            }
            name="region"
          />

          {fishnetLayer !== null && (
            <TileLayer
              name="fishnetWms"
              source={wms({
                url: "http://65.21.126.12:8090/geoserver/agtull/wms",
                layer: `agtull:${fishnetLayer.layerName}`,
                filter: `BBOX(geom, ${fishnetLayer.wktGeom})`,
              })}
              zIndex={2}
            />
          )}
        </MyLayers>
      </AdvisoryMap>
    </>
  );
}

export default MapViewAnalyticsAdvisory;
