import React, { useState, useEffect } from 'react';
import '../mainScreen.css';
import { Select, MenuItem, InputLabel, FormControl } from '@mui/material';
import TextField from '@mui/material/TextField';
import { useDispatch, useSelector } from "react-redux";
import { changeCountryName, changeSelectedDatasetname, changeSelectedDatatype, changeendDate, changeproductionDate, changestartDate, changeCropParameterFile, changeVersionNumber } from '../redux/action';
import CoordinatesPopUp from './CoordinatesPopUp'
import { FaUpload } from "react-icons/fa";
import { FaAnglesLeft } from "react-icons/fa6";
import { FaAnglesRight } from "react-icons/fa6";
import { Auth } from "aws-amplify";


const LeftPanel = (props) => {
  const [age, setAge] = React.useState('');
  const [datatype, setdatatype] = useState([]);
  const [dataset, setDataset] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const selecteddatatype = useSelector((state) => state.selecteddatatype);
  const selecteddataset = useSelector((state) => state.selecteddataset);
  const startDateSelected = useSelector((state) => state.startDateSelected);
  const productionDateSelected = useSelector((state) => state.productionDateSelected);
  const endDateSelected = useSelector((state) => state.endDateSelected);
  const [openCoordinatePopup, setOpenCoordinatePopup] = useState(false);
  const [clearMarkers, setClearMarkers] = useState(false)
  const [clickedButton, setClickedButton] = useState('map');
  const a = useSelector((state) => state.level1Data)
  const b = useSelector((state) => state.level2Data)
  const c = useSelector((state) => state.level3Data)
  const versionNumber = useSelector((state) => state.versionNumber);
  const enterprise = useSelector((state)=>state.enterprise)

  const dispatch = useDispatch();
  const handleVersionChange = (e) => {
    dispatch(changeVersionNumber(e.target.value)); 
  };
  useEffect(() => {
    dispatch(changeCropParameterFile(null))
    async function fetchData() {
      try {
        const { accessToken: { jwtToken } } = await Auth.currentSession();
        const datatypeResponse = await fetch(`/api/homepage/datatype`, {
          method: "GET",
          headers: {
            "Content-Type": "application/json",
            Authorization: jwtToken,
          },
        });
        if (datatypeResponse.ok) {
          const datatypedata = await datatypeResponse.json();
          setdatatype(datatypedata);
        } else {
          console.error('Failed to fetch data types');
        }
      } catch (error) {
        console.error('Error fetching data types:', error);
      }
    }
    fetchData();
  }, []);

  useEffect(() => {
    async function fetchDataset() {
      try {
        const {
          accessToken: { jwtToken },
        } = await Auth.currentSession();
        if (selecteddatatype !== '') {
          const datasetResponse = await fetch(`/api/homepage/rainfalldatasetname?datatype=Rainfall`, {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: jwtToken,
            },
          });
          if (datasetResponse.ok) {
            const datasetdata = await datasetResponse.json();
            setDataset(datasetdata);
          } else {
            console.error('Failed to fetch datasets');
          }
        }
      } catch (error) {
        console.error('Error fetching datasets:', error);
      }
    }
    fetchDataset();
  }, [selecteddatatype]);

  useEffect(() => {
    if (selecteddatatype === "Rainfall") {
      if (selecteddatatype && selecteddataset && productionDateSelected && age) {
        setSubmitDisabled(false);
      } else {
        setSubmitDisabled(true);
      }
    } else if (selecteddatatype === "Vegetation" || selecteddatatype === "Soil Moisture") {
      if (selecteddatatype && selecteddataset) {
        setSubmitDisabled(false);
      } else {
        setSubmitDisabled(true);
      }
    } else {
      setSubmitDisabled(true);
    }
  }, [selecteddatatype, selecteddataset, productionDateSelected, startDateSelected, endDateSelected, age]);

  const handleSubmit = () => {
    if (!a || !b || !c) {
      alert("Please select all three levels from map");
      return;
    }
    if (selecteddatatype === 'Rainfall') {
      props.setcalulateriskmainscreen(false);
      props.setriskscreen(true);
    } else {
      props.setGraph(selecteddataset)
    }
  };

  const handleButtonClick = (button) => {
    setClickedButton(button);
  };

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    //console.log("Uploaded file:", file);
    const formData = new FormData();
    if (file) {
      formData.append('file', file);
      dispatch(changeCropParameterFile(formData))
    }
    
  };

  const handleChange = (event) => {
    dispatch(changeSelectedDatatype(event.target.value));
    dispatch(changeSelectedDatasetname(null));
    setAge('')
  };

  const handlefuncttionChange = (event) => {
    setAge(event.target.value);
  };

  const handledatasetnameChange = (event) => {
    dispatch(changeSelectedDatasetname(event.target.value));
  };

  const handleSetOpen = (e) => {
    setOpenCoordinatePopup(e)
  };

  const handleMapClick = () => {
    props.loadMap();
    handleButtonClick('map');
    setClearMarkers(!clearMarkers)
  };

  const handleDownloadTemplate = async () => {
    const { accessToken: { jwtToken } } = await Auth.currentSession();
    try {
      const response = await fetch(`/api/homepage/downloadCropParameterTemplate?enterprise=${enterprise}`, {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: jwtToken,
        },
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const blob = await response.blob();
      const url = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', 'crop_parameter_template.csv');
      document.body.appendChild(link);
      link.click();
      link.parentNode.removeChild(link);

    } catch (error) {
      console.error('Download error:', error);
    }
  };

  const handleClearSelection = () => {
    props.handleClearSelection();
    dispatch(changeCropParameterFile(null));
  };

  return (
    <div className={`left-panel ${isCollapsed ? 'collapsed' : ''}`}>
     <div className="collapse-button" onClick={() => setIsCollapsed(!isCollapsed)}>
        {isCollapsed ? <FaAnglesRight /> : <FaAnglesLeft />}
      </div>
     
      
      {!isCollapsed && (
        <>
         <div className="heading" style={{ color: '#000', fontSize: '3.5vh', fontStyle: 'normal', fontWeight: 900, lineHeight: '3vh', letterSpacing: '0.46px', textTransform: 'uppercase', marginBottom: '15px' }}>
        PARAMETERS
      </div>
          <div className="subheading" style={{ color: '#000', fontSize: '14px', fontWeight: 500, lineHeight: '2vh', letterSpacing: '0.46px', textTransform: 'uppercase', marginBottom: '5px' }}>
            Set Your Region of interest
          </div>
          <div className="custom-bar">
            <div className={`custom-buttontop ${clickedButton === 'map' ? 'clicked' : ''}`} style={{ marginRight: '10px', width: '30%' }} onClick={handleMapClick}>Map</div>
            <div className={`custom-buttontop ${clickedButton === 'upload' ? 'clicked' : ''}`} onClick={() => handleButtonClick('upload')} style={{ marginRight: '10px', width: '45%' }}>Upload Shapefile</div>
            <div
              className={`custom-buttontop ${clickedButton === 'coordinates' ? 'clicked' : ''}`}
              style={{ width: '40%' }}
              onClick={() => {
                setOpenCoordinatePopup(true);
                setClickedButton('coordinates');
              }}
            >
              Coordinates
            </div>
          </div>
          <CoordinatesPopUp setOpen={handleSetOpen} open={openCoordinatePopup} removeAlllayers={props.removeAlllayers} clearMarkers={clearMarkers} />
          {clickedButton === "upload" ?
            <div >
              <input
                type="file"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
                id="uploadFileInput"
              />
              <label htmlFor="uploadFileInput" className="custom-button" style={{ cursor: 'pointer', width: '100%', background: '#D9D9D9', height: '7vh' }}>
                <FaUpload color='grey' size={25} />
              </label>
            </div>
            :
            null}
          <div className="dataset-heading" style={{ color: '#000', fontSize: '15px', fontWeight: 500, lineHeight: '3vh', letterSpacing: '0.46px', fontFeatureSettings: "'clig' off, 'liga' off", marginBottom: '0.7vh' }}>
            Data Sets
          </div>
          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Data type</InputLabel>
            <Select
              style={{ height: clickedButton === 'upload' ? '5.5vh' : '6vh', marginBottom: '2vh', fontSize: '14px', alignItems: 'center' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selecteddatatype}
              label="Data type"
              onChange={handleChange}
            >
              <MenuItem style={{ fontSize: '14px' }} value="Rainfall">Rainfall</MenuItem>
            </Select>
          </FormControl>

          <FormControl fullWidth>
            <InputLabel id="demo-simple-select-label">Dataset Name</InputLabel>
            <Select
              style={{ height: clickedButton === 'upload' ? '5.5vh' : '6vh', marginBottom: '1vh', fontSize: '14px' }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={selecteddataset}
              label="Dataset Name"
              onChange={handledatasetnameChange}
            >
              {dataset.length > 0 ? (
                dataset.map((item, index) => (
                  <MenuItem style={{ fontSize: '14px' }} key={index} value={item}>{item}</MenuItem>
                ))
              ) : null}
            </Select>
          </FormControl>

          <div className="dataset-heading" style={{ color: selecteddatatype === 'Rainfall' || selecteddatatype === "" ? 'black' : 'rgba(0, 0, 0, 0.38)', fontSize: '15px', fontWeight: 500, lineHeight: '26px', letterSpacing: '0.46px', fontFeatureSettings: "'clig' off, 'liga' off", }}>
            Model Type
          </div>

          <FormControl fullWidth>
            <InputLabel sx={{
              color: selecteddatatype === 'Rainfall' || selecteddatatype === "" ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.38)', // Change label color based on disabled state
            }}
              id="demo-simple-select-label">Function Name</InputLabel>
            <Select
              sx={{
                height: clickedButton === 'upload' ? '5.5vh' : '6vh',
                marginBottom: '1vh',
                fontSize: '14px',
                color: selecteddatatype !== 'Rainfall' ? 'rgba(0, 0, 0, 0.38)' : 'black',
                '& .MuiSelect-icon': {
                  color: selecteddatatype !== 'Rainfall' ? 'rgba(0, 0, 0, 0.38)' : 'black',
                }
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Function Name"
              onChange={handlefuncttionChange}
              disabled={selecteddatatype !== 'Rainfall'}
            >
              <MenuItem value={"Simple Average"}>Simple Average</MenuItem>
            </Select>
          </FormControl>
         
                  <div className="dataset-heading" style={{ color: selecteddatatype === 'Rainfall' || selecteddatatype === "" ? 'black' : 'rgba(0, 0, 0, 0.38)', fontSize: '15px', fontWeight: 500, lineHeight: '26px', letterSpacing: '0.46px', fontFeatureSettings: "'clig' off, 'liga' off", }}>
                  Parameter table
          </div>
                

          <FormControl fullWidth>
            <InputLabel sx={{
              color: selecteddatatype === 'Rainfall' || selecteddatatype === "" ? 'rgba(0, 0, 0, 0.6)' : 'rgba(0, 0, 0, 0.38)', // Change label color based on disabled state
            }}
              id="demo-simple-select-label"> Version number</InputLabel>
            <Select
              sx={{
                height: clickedButton === 'upload' ? '5.5vh' : '6vh',
                marginBottom: '1vh',
                fontSize: '14px',
                color: selecteddatatype !== 'Rainfall' ? 'rgba(0, 0, 0, 0.38)' : 'black',
                '& .MuiSelect-icon': {
                  color: selecteddatatype !== 'Rainfall' ? 'rgba(0, 0, 0, 0.38)' : 'black',
                }
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={versionNumber}
              label="Function Name"
              onChange={handleVersionChange}
            >
              <MenuItem value={1}>1</MenuItem>
                    <MenuItem value={2}>2</MenuItem>
            </Select>
          </FormControl>
              
          <div className="dataset-heading" style={{ color: '#000', fontSize: '15px', fontWeight: 500, lineHeight: '26px', letterSpacing: '0.46px',  fontFeatureSettings: "'clig' off, 'liga' off", marginBottom: '10px' }}>
            Production Start Date
          </div>
          <div style={{ marginRight: '10px', color: 'black', marginBottom: '10px' }}>
            <TextField
              type="date"
              label="Start Date"
              value={productionDateSelected}
              disabled={selecteddatatype === 'Vegetation' || selecteddatatype === 'Soil Moisture'}
              onChange={(e) => {
                dispatch(changeproductionDate(e.target.value));
              }}
              InputLabelProps={{
                shrink: true,
              }}
              inputProps={{
                min: "2024-01-01",
              }}
              fullWidth
              size="small"
            />
          </div>
          <div className="dataset-heading" style={{ color: selecteddatatype === "Rainfall" ? 'black' : 'rgba(0, 0, 0, 0.38)', fontSize: '15px', fontWeight: 500, lineHeight: '3vh', letterSpacing: '0.46px', textTransform: 'uppercase', fontFeatureSettings: "'clig' off, 'liga' off", marginBottom: clickedButton === 'upload' ? '6px' : '10px' }}>
            Upload parameter table
            <span className="dataset-heading" style={{ fontSize: '15px', fontWeight: 500, lineHeight: '26px', textTransform: 'Capitalize', letterSpacing: '0.46px', fontFeatureSettings: "'clig' off, 'liga' off", color: selecteddatatype === "Rainfall" ? '#F5821F' : 'rgba(0, 0, 0, 0.38)' }} onClick={handleDownloadTemplate}>
              (Download template)
            </span>
          </div>
          <div >
            <input
              type="file"
              onChange={handleFileUpload}
              style={{ display: 'none' }}
              id="uploadFileInput"
              disabled={selecteddatatype !== "Rainfall"}
            />
            <label htmlFor="uploadFileInput" className="custom-button" style={{ cursor: selecteddatatype === "Rainfall" ? 'pointer' : 'not-allowed', width: '100%', background: '#D9D9D9', height: '7vh' }}>
              <img src="upload.jpg" alt="Icon" className="icon" style={{ height: '6vh', width: '4vw' }} />
            </label>
          </div>
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            <div className="custom-button" style={{ width: '50%', backgroundColor: submitDisabled ? '#ccc' : '#00253D' }}
              onClick={handleSubmit}
              disabled={submitDisabled}
            >
              Submit
            </div>
            <div className="custom-button" style={{ width: '50%', display: 'flex', flexDirection: 'row' }} onClick={handleClearSelection}>
              Clear Selection
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default LeftPanel;
